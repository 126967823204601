<template>
  <div class="contents" id="contents">
    <div class="inner-sm">
      <!-- 이용약관 -->
      <div class="terms-title">
        <h3>{{ $t('content.signup.popup.Terms.txt01') }}</h3>
      </div>

      <div class="view-box">
        <TermsText />
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import TermsText from './component/TermsText.vue';

/**
 * 이용약관
 */
export default {
  name: 'Terms',
  head: {
    meta: [
      { name: 'viewport', content: 'width=1920' },
    ],
  },
  mixins: [
    mixinHelperUtils,
  ],

  components: {
    TermsText,
  },
};
</script>
